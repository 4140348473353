import { Card, Button, Form } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import { useState } from "react";

function ProductCard(props) {
    // props.product is the product we are selling
    const product = props.product;
    const [isOnCooldown, setIsOnCooldown] = useState(false);
    const [selectedSize, setSelectedSize] = useState(product.sizes ? product.sizes[0] : "");

    const handleSizeChange = (event) => {
        setSelectedSize(event.target.value);
    };

    const addToCart = async () => {
        setIsOnCooldown(true);
        localStorage.setItem('cartAmmount', localStorage.getItem('cartAmmount') ? localStorage.getItem('cartAmmount') : 0);
        localStorage.setItem('cartAmmount', parseInt(localStorage.getItem('cartAmmount')) + 1);
        const response = await fetch('https://shop.gallowayhillbillies.org/api/add-item', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                productId: product.id,
                size: selectedSize,
                sessionId: localStorage.getItem('sessionId'),
                price: product.price,
            }),
        });
        

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log(data);
        setTimeout(() => {
            setIsOnCooldown(false);
        }, 200);
    };

    return (
        <Card>
            <Card.Body>
                <Card.Title>{product.title}</Card.Title>
                {product.image && product.image2 && (
                    <Carousel className="image-container">
                        <Carousel.Item interval={9999999} >
                            <Card.Img src={product.image} alt="Hoodie Front" className="product-image" />
                        </Carousel.Item>
                        <Carousel.Item interval={9999999} >
                            <Card.Img src={product.image2} alt="Hoodie Back" className="product-image" />
                        </Carousel.Item>
                    </Carousel>
                )}
                {(!product.image || !product.image2) && (
                    <div className="image-container">
                        <Card.Img src={product.image || product.image2} />
                    </div>
                )}
                <Card.Text>£{product.price}</Card.Text>
                {product.sizes && (
                    <Form.Select aria-label="Default select example" onChange={handleSizeChange}>
                        {product.sizes.map((size, index) => (
                            <option key={index} value={size}>
                                {size}
                            </option>
                        ))}
                    </Form.Select>
                )}

                <Button variant="primary" onClick={addToCart} disabled={isOnCooldown}>
                    Add To Cart
                </Button>
            </Card.Body>
        </Card>
    );
}

export default ProductCard;