import React from "react";
import { getProductData } from "../productsStore";

function CartProduct(props) {
    const id = props.id;
    const productData = getProductData(id);
    const size = props.size;
    


    const removeItem = async () => {
        await fetch("https://shop.gallowayhillbillies.org/api/remove-item", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ id: id, sessionId: localStorage.getItem("sessionId"), size: size}),
        })
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                console.log(response);
                localStorage.setItem("cartAmmount", localStorage.getItem("cartAmmount") - response.quantity);
                window.location.reload(true);
            })
            
    };  
    
    const decrementQuantity = async () => {
        await fetch("https://shop.gallowayhillbillies.org/api/decrement-quantity", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ id: id, sessionId: localStorage.getItem("sessionId"), size: size}),
        })
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                localStorage.setItem("cartAmmount", localStorage.getItem("cartAmmount") - 1);
                console.log(response);
                window.location.reload(true);
            });
    }

    const incrementQuantity = async () => {
        await fetch("https://shop.gallowayhillbillies.org/api/increment-quantity", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ id: id, sessionId: localStorage.getItem("sessionId"), size: size}),
        })
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                console.log(response);
                localStorage.setItem("cartAmmount", Number(localStorage.getItem("cartAmmount")) + 1);
                window.location.reload(true);
            });
    }

    return (
        <>
            <h3>{productData.title}</h3>
            <p>£{(productData.price).toFixed(2)}</p>
            <p>Size: {size}</p>         
            <p>Quantity: {props.quantity}</p>
            <button type="submit" onClick={decrementQuantity}> - </button>
            <button onClick={incrementQuantity}> + </button>
            <br></br>
            <button  type="submit" size="sm" onClick={removeItem}>
                Remove
            </button>
            <hr></hr>
        </>
    );
}

export default CartProduct;
