import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { Row, Col } from "react-bootstrap";
import { productsArray } from "../productsStore";
import ProductCard from "../components/ProductCard";

function Store() {
    useEffect(() => {
        if (localStorage.getItem("sessionIdTime") !== null) {
            const currentTime = new Date().getTime();
            const oldTime = localStorage.getItem("sessionIdTime");
            if (currentTime - oldTime > 1000 * 60 * 60 * 24) {
                localStorage.setItem("sessionId", uuidv4());
                localStorage.setItem("sessionIdTime", new Date().getTime());
            }
        }
        if (localStorage.getItem("sessionId") === null) {
            localStorage.setItem("sessionId", uuidv4());
            localStorage.setItem("sessionIdTime", new Date().getTime());
        }
        console.log(localStorage.getItem("sessionId"));
        console.log(localStorage.getItem("sessionIdTime"));
    }, []);

    return (
        <><h2 align="center" >Hoodies pre-order by Sunday 5th of May</h2>
        <h2 align="center" >Tech shirts pre-order by Friday 19th of April</h2>
        <h2 align="center" >to collect from Duathlon</h2>
            <Row xs={1} md={3} className="g-4">
                {productsArray.map((product, idx) => (
                    <Col align="center" key={idx}>
                        <ProductCard product={product} />
                    </Col>
                ))}
            </Row>
        </>
    );
}

export default Store;
